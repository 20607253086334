.product_listing_container {
  background-color: #f4f3ec;

  .filter_header {
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 62.1px */
  }

  .product_title,
  .product_description {
    font-style: normal;
    font-weight: 400;
  }
  .product_title {
    /* h2 */
    font-size: 3rem;
    line-height: 120%; /* 57.6px */
  }

  .product_description {
    color: #4d4d4d;
    font-size: 1.125rem;
    line-height: 130%;
    max-width: 500px;
  }
}

.product_listing_card {
  img {
    border-radius: 16px;
    object-fit: cover;
    height: 600px;
  }
}

@media only screen and (max-width: 1280px) {
  .product_description {
    padding-right: 5%;
  }

  .product_listing_card {
    img {
      height: 540px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .product_listing_card {
    img {
      height: 440px;
    }
  }
}

@media only screen and (max-width: 860px) {
  .product_listing_container {
    .product_title {
      font-size: 2.5rem;
    }
  }

  .product_description {
    padding-right: 0%;
  }

  .product_listing_card {
    img {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .product_listing_card {
    img {
      height: 370px;
    }
  }
}
