.clients_container {
  .header_text,
  .text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
  }
  .header_text {
    color: #fff;
    font-size: 3.375rem;
    line-height: 115%; /* 62.1px */
    // max-width: 1200px;
  }
  .text {
    color: #dcdcdc;
    font-size: 16px;
    line-height: 1.2;
    max-width: 935px;
  }

  .card_container {
    // min-height: 13rem;
    .card_header {
      align-self: stretch;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
    }
  }
}

@media only screen and (max-width: 640px) {
  .clients_container {
    .header_text {
      width: 100%;
      font-size: 2.5rem;
    }

    .text {
      width: 100%;
    }
  }
}
