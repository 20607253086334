.product_listing_container {
  background-color: #f4f3ec;
  padding-bottom: 0!important;

  .filter_header {
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 62.1px */
  }

  .product_title,
  .product_description {
    font-style: normal;
    font-weight: 400;
  }
  .product_title {
    /* h1 */
    font-size: 3rem;
    line-height: 115%; /* 62.1px */
  }

  .product_description {
    color: #4d4d4d;
    font-size: 1.125rem;
    line-height: 130%;
  }
}


@media only screen and (max-width: 640px) {
  .product_listing_container{
    padding-top: 60px;
  }
}